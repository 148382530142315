import React, { SyntheticEvent } from "react";
import { NavLink } from "react-router-dom";
import EVGenericText from "../../../../components/ev-generic-components/EVGenericText";
import INavItem from "../../interfaces/NavItem";
import "./NavItem.scss";

const NavItem: React.FC<INavItem> = ({ isEnabled, name, route }) => {
  const getClassName = ({ isActive }: { isActive: boolean }): string => {
    const suffix = "flex-center";
    if (isActive) return "nav-link-active " + suffix;
    if (!isEnabled) return "nav-link-disabled " + suffix;
    return "nav-link " + suffix;
  };

  const onClick = (e: SyntheticEvent) => {
    if (!isEnabled) e.preventDefault();
  };

  return (
    <NavLink to={route} className={getClassName} onClick={onClick}>
      <EVGenericText text={name} />
    </NavLink>
  );
};

export default NavItem;
