import React, { useState } from "react";
import { AccountLogo } from "../../../../assets/images";
import EVGenericText from "../../../../components/ev-generic-components/EVGenericText";
import { useAppSelector } from "../../../../state/hooks";
import { selectWalletInfo } from "../../../../state/wallet-info-state";
import { SignOutPopup } from "./SignOutPopup";
import "./NavBarAccountDetails.scss";

const NavBarAccountDetails: React.FC = () => {
  const address = useAppSelector(selectWalletInfo("address")) as string;

  const [signoutPopupOpen, setSignoutPopupOpen] = useState(false);

  return (
    <div className="account-details-container flex justify-end align-center">
      {!!address && <EVGenericText text={address} extraClassNames={"m-mr"} />}
      <img
        src={AccountLogo}
        alt="account"
        onClick={() => setSignoutPopupOpen(!signoutPopupOpen)}
      />
      {signoutPopupOpen && <SignOutPopup />}
    </div>
  );
};
export default NavBarAccountDetails;
