import { ethers } from "ethers";
import OutputI from "../../../interfaces/Output";
import { INftsStakingData } from "../get-wallet-info/interfaces/IStakeNftsAsyncActionResponse";
import fetchNftsStakingData from "../utils/fetchNftsStakingData";

import ContractIdentifiers from "../../../constants/contractIdentifiers";
import getContractInstance from "../utils/getContractInstance";

/**
 * @KASH
 * @TODO - Unstake nfts.
 * @param provider - A Web3Provider signed by the user's connected wallet.
 * @param nftIds - the ids of the nfts to be unstaked.
 * @returns  All the data that needs to updated after unstaking action: staked nfts array, unstaked nfts array and the earn rate.
 */
const unstakeNfts = async (nftIds: number[], provider: ethers.providers.Web3Provider): Promise<OutputI<INftsStakingData>> => {
    try {
        // unstaking code goes here...

        let stakingContract = await getContractInstance(provider, ContractIdentifiers.app.EternalVikingsStaking);
        let tx = await stakingContract.connect(await provider.getSigner()).unstakeVikings(nftIds);
        await tx.wait()
        
        // fetching updated data.
        const updatedNftsStakingDataResponse = await fetchNftsStakingData(provider);
        if(updatedNftsStakingDataResponse.err) throw new Error(updatedNftsStakingDataResponse.msg);
        if(updatedNftsStakingDataResponse.output === undefined) throw new Error("New data is undefined!");
        const output: INftsStakingData = updatedNftsStakingDataResponse.output;
        return {err: false, output: output};
    } catch (error) {
        return {err: true, msg: `unstake-nfts/index.ts::unstakeNfts():: Failed unstake nfts! || \n${error}`};
    }
}

export default unstakeNfts;