import INavItem from "../interfaces/NavItem";
export default [
    {
        route: "/account",
        name: "My Account",
        isEnabled: true
    },
    {
        route: "/marketplace",
        name: "Marketplace",
        isEnabled: true
    },
    {
        route: "/evWallet",
        name: "EV Wallet",
        isEnabled: false
    },
] as INavItem[]