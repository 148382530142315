import React from "react";
import "./EVGenericText.scss";

export type EVGenericTextSize =
  | "huge"
  | "x-large"
  | "large"
  | "medium"
  | "small"
  | "x-small"
  | "custom";
export type EVGenericTextType = "h1" | "h2" | "h3" | "p";
export type EVGenericWeightType = "regular" | "bold";
export type EVGenericTextProps = {
  text: string;
  size?: EVGenericTextSize;
  type?: EVGenericTextType;
  weight?: EVGenericWeightType;
  customCSS?: React.CSSProperties;
  extraClassNames?: string;
  onClick?: () => void;
};

const EVGenericText: React.FC<EVGenericTextProps> = ({
  text,
  customCSS,
  extraClassNames,
  type = "p",
  size = "small",
  weight = "regular",
  onClick,
}) => {
  const className = `ev-generic-text-${size} weight-${weight} ${
    extraClassNames ? extraClassNames : ""
  }`;
  const content = text ? text : "My text";
  return (
    <>
      {type === "p" && (
        <p
          className={className}
          onClick={onClick && onClick}
          style={customCSS ? customCSS : {}}
        >
          {content}
        </p>
      )}
      {type === "h1" && (
        <h1
          className={className}
          onClick={onClick && onClick}
          style={customCSS ? customCSS : {}}
        >
          {content}
        </h1>
      )}
      {type === "h2" && (
        <h2
          className={className}
          onClick={onClick && onClick}
          style={customCSS ? customCSS : {}}
        >
          {content}
        </h2>
      )}
      {type === "h3" && (
        <h3
          className={className}
          onClick={onClick && onClick}
          style={customCSS ? customCSS : {}}
        >
          {content}
        </h3>
      )}
    </>
  );
};

export default EVGenericText;
