import { createAsyncThunk } from '@reduxjs/toolkit';
import { IWalletInfoSliceState } from '..';
import OutputI from '../../../interfaces/Output';
import connectWallet from '../../../services/ethers/connect-wallet';
import getWalletInfoRequest from '../../../services/ethers/get-wallet-info/getWalletInfo';
import AsyncActionStatus from '../../constants/asyncActionStatus';
import ReducerSliceNames from '../../constants/reducerSliceNames';

export const connectWalletActionName = 'ConnectWallet';
const connectWalletAsyncAction = createAsyncThunk(
  `${ReducerSliceNames.WALLET_INFO}/${connectWalletActionName}`,
  async (): Promise<OutputI<IWalletInfoSliceState>> => {
    try {
      const connectWalletResult = await connectWallet();
      if(connectWalletResult.err) throw new Error(connectWalletResult.msg);
      if(!connectWalletResult.output) throw new Error("Wallet address is undefined");
      const getWalletInfoResponse = await getWalletInfoRequest(connectWalletResult.output);
      if(getWalletInfoResponse.err) throw new Error(getWalletInfoResponse.msg);
      if(!getWalletInfoResponse.output) throw new Error("Wallet address is undefined");
      const output = {
        provider: connectWalletResult.output,
        address: await connectWalletResult.output.getSigner().getAddress(),
        ...getWalletInfoResponse.output,
        status: AsyncActionStatus.IDLE,
        mintVikingSuccess: false
      }
      return { err: false, output };
    } catch (error) {
      const msg = `connectWalletAsyncAction.ts::connectWalletAsyncAction():: Failed to connect wallet! | \n${error}`;
      return { err: true, msg };
    }
  }
);
export default connectWalletAsyncAction;