import { createAsyncThunk } from "@reduxjs/toolkit";
import { ethers } from "ethers";
import { selectWalletInfo } from "..";
import OutputI from "../../../interfaces/Output";
import placeAuctionBid from "../../../services/ethers/place-auction-bid";
import ReducerSliceNames from "../../constants/reducerSliceNames";
import { useAppSelector } from "../../hooks";

export const placeAuctionBidActionName = 'AuctionBid';

/**
 * @returns The new a amount of gold after claiming.
 */
const placeAuctionBidAsyncAction = createAsyncThunk(
  `${ReducerSliceNames.WALLET_INFO}/${placeAuctionBidActionName}`,
  async (
    {provider, bid}:
    {provider: ethers.providers.Web3Provider, bid: number}): Promise<OutputI<{
        fetchCurrentGoldResult: number,
        fetchHighestBid: number,
        fetchHighest: number
    }>> => {
    try {   
      const claimGoldResult = await placeAuctionBid(provider, bid);
      if(claimGoldResult.err) throw new Error(claimGoldResult.msg);
      if(claimGoldResult.output === undefined) throw new Error("New gold amount is undefined");
      const output = claimGoldResult.output;
      return { err: false, output };
    } catch (error) {
      const msg = `placeAuctionBidAsyncAction.ts::placeAuctionBidAsyncAction():: Failed to claim gold! | \n${error}`;
      return { err: true, msg };
    }
  }
);
export default placeAuctionBidAsyncAction