import { ethers } from "ethers";
import INftData from "../../../interfaces/NftData";
import OutputI from "../../../interfaces/Output";
import injectNftsImages from "../../../utils/injectNftsImages";

import getSignerAddress from "../utils/getSignerAddress";
import ContractIdentifiers from "../../../constants/contractIdentifiers";
import getContractInstance from "../utils/getContractInstance";

/**
 * @KASH
 * @TODO - Get the ids of the staked nfts.
 * @param provider - A Web3Provider signed by the user's connected wallet.
 * @returns An array of the staked nfts.
 */
const fetchStakedNfts = async (provider: ethers.providers.Web3Provider): Promise<OutputI<INftData[]>> => {
    try {
        let wallet = await getSignerAddress(provider)
        let stakingContract = await getContractInstance(provider, ContractIdentifiers.app.EternalVikingsStaking);
        let userStakedTokens = await stakingContract.getStakedVikingIdsOfUser(wallet);
        let tokenTimestamps = await stakingContract.getStakedTimestampsOfVikings(userStakedTokens);

        const nftsWithoutImage: Omit<INftData, 'imgURL'>[] = userStakedTokens.map((t: ethers.BigNumber, i: number) => {
            let timestamp = tokenTimestamps[i];
            let now = Math.floor(Date.now() / 1000);
            let daysSinceStaked = ethers.BigNumber.from(now).sub(timestamp).div(86400).toNumber();
            return {
                id: t.toNumber(),
                numOfDaysStaked: daysSinceStaked
            }
        })

        // Fetch nft images
        const injectNftsImagesResult = await injectNftsImages(nftsWithoutImage);
        if (injectNftsImagesResult.err) throw new Error(injectNftsImagesResult.msg);
        if (injectNftsImagesResult.output === undefined) throw new Error("injectNftsImages output is undefined");

        const output = injectNftsImagesResult.output;
        return {err: false, output};
    } catch (error) {
        return {err: true, msg: `fetch-staked-nfts/index.ts::fetchStakedNfts():: Failed to fetch the staked nfts! || \n${error}`};
    }
}

export default fetchStakedNfts;