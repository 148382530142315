import React from "react";
import EVGenericButton from "../../../../../../components/ev-generic-components/EVGenericButton";
import EVGenericText from "../../../../../../components/ev-generic-components/EVGenericText";
import { StakingPanelType } from "../../constants/texts";
import "./StakingGridHeader.scss";

export type StakingGridHeaderProps = LeftProps & RightProps;

const StakingGridHeader: React.FC<StakingGridHeaderProps> = ({
  totalNfts,
  stakingPanelTitle,
  selectAll,
}) => (
  <div className="staking-grid-header flex space-between align-center w-all m-pad">
    <Left stakingPanelTitle={stakingPanelTitle} />
    <Right totalNfts={totalNfts} selectAll={selectAll} />
  </div>
);
export default StakingGridHeader;

type LeftProps = {
  stakingPanelTitle: StakingPanelType;
};

const Left: React.FC<LeftProps> = ({ stakingPanelTitle }) => (
  <div className="staking-grid-header-left">
    <EVGenericText text={stakingPanelTitle} size={"x-small"} type={"h3"} />
  </div>
);

type RightProps = {
  totalNfts: number;
  selectAll: () => void;
};

const Right: React.FC<RightProps> = ({ totalNfts, selectAll }) => (
  <div className="staking-grid-header-right flex justify-end align-center">
    <EVGenericText text={String(totalNfts)} extraClassNames={"l-mr"} />
    <EVGenericButton text={"Select All"} isDark={true} cb={selectAll} />
  </div>
);
