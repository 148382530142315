import { ethers } from "ethers";
import OutputI from "../../../interfaces/Output";
import fetchEarnRate from "../fetch-earn-rate";
import fetchStakedNfts from "../fetch-staked-nfts";
import fetchUnstakedNfts from "../fetch-unstaked-nfts";
import { INftsStakingData } from "../get-wallet-info/interfaces/IStakeNftsAsyncActionResponse";

const fetchNftsStakingData = async (provider: ethers.providers.Web3Provider): Promise<OutputI<INftsStakingData>> => {
    try {
        const [unstakedNfts, stakedNfts] = await Promise.all([fetchUnstakedNfts(provider), fetchStakedNfts(provider)]);
        if (unstakedNfts.err)
            throw new Error(unstakedNfts.msg);
        if (unstakedNfts.output === undefined)
            throw new Error("Unstaked Nfts array is undefined");
        if (stakedNfts.err)
            throw new Error(stakedNfts.msg);
        if (stakedNfts.output === undefined)
            throw new Error("Staked Nfts array is undefined");
        let earnRate = 0;
        if (stakedNfts.output.length) {
            const earnRateResponse = await fetchEarnRate(provider);
            if (earnRateResponse.err)
                throw new Error(earnRateResponse.msg);
            if (earnRateResponse.output === undefined)
                throw new Error("Earn rate is undefined");
            earnRate = earnRateResponse.output;
        }
        const output: INftsStakingData = {
            stakedNfts: stakedNfts.output,
            unstakedNfts: unstakedNfts.output,
            earnRate: earnRate,
        };
        return { err: false, output};
    } catch (error) {
        return { err: true, msg: `fetchNftsStakingData.ts::fetchNftsStakingData():: Failed to fetch nfts staking data! || \n${error}`};
    }
}

export default fetchNftsStakingData;