import { ethers } from "ethers";
import OutputI from "../../../interfaces/Output";

/**
 * @KASH
 * @TODO - Force Metamask gold coin sync.
 * @param provider - A Web3Provider signed by the user's connected wallet.
 * @returns A bool indicating if the action was successful.
 */
const syncMetamask = async (provider: ethers.providers.Web3Provider): Promise<OutputI<boolean>> => {
    try {
        const wasAdded = await window.ethereum.request({
            method: 'wallet_watchAsset',
            params: {
              type: 'ERC20', // Initially only supports ERC20, but eventually more!
              options: {
                address: '0x62f5C63FdB767aD1e891A872f39E3852c33132Ad', // The address that the token is at.
                symbol: "$GOLD", // A ticker symbol or shorthand, up to 5 chars.
                decimals: 18, // The number of decimals in the token
                image: "", // A string url of the token logo
              },
            },
          });
        
        const successful = wasAdded;
        return {err: false, output: successful};
    } catch (error) {
        return {err: true, msg: `sync-metamask/index.ts::syncMetamask():: Failed to sync metamask! || \n${error}`};
    }
}

export default syncMetamask;