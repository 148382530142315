import React from "react";
import EVGenericText from "../../../../components/ev-generic-components/EVGenericText";
import MintVikingComponent from "./components/mint-viking-component/MintVikingComponent";
import CurrencyDisplayComponent from "./components/currency-display-component/CurrencyDisplayComponent";

import "./LayoutMintVikings.scss";
import { useAppDispatch, useAppSelector } from "../../../../state/hooks";
import {
  getWalletInfo,
  IWalletInfoSliceState,
} from "../../../../state/wallet-info-state";
import mintVikingAsyncAction from "../../../../state/wallet-info-state/actions/mintVikingAsyncAction";
import stakeNftsAsyncAction from "../../../../state/wallet-info-state/actions/stakeNftsAsyncAction";
import unstakeNftsAsyncAction from "../../../../state/wallet-info-state/actions/unstakeNftsAsyncAction";
import { openPopup } from "../../../../state/popup-state";

import { ethers } from "ethers"
import { selectWalletInfo } from "../../../../state/wallet-info-state";

const LayoutMintVikings: React.FC = () => {
    const dispatch = useAppDispatch();
    
    const { 
        currentGold, 
        earnRate, 
        provider,
        mintVikingSuccess 
    } = useAppSelector(
        getWalletInfo()
    ) as IWalletInfoSliceState;

    const onMintVikingClick = () => {
        dispatch(
          mintVikingAsyncAction(provider)
        );
    };

    return (
        <section className="layout-mint-vikings-container flex column justify-start align-center xl-mt w-all">
            <CurrencyDisplayComponent 
                provider={provider}
                currentGold={currentGold}
                earnRate={earnRate}
            />
            <MintVikingComponent 
                actionFn={onMintVikingClick}
                currentGold={currentGold}
                mintVikingSuccess={mintVikingSuccess}
            />
        </section>
    );
}
export default LayoutMintVikings;