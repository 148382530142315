import INftData from "../interfaces/NftData";
import OutputI from "../interfaces/Output";
import { fetchImageByNftId } from "../services/api";

const injectNftsImages = async (nftsWithoutImages: Omit<INftData, 'imgURL'>[]): Promise<OutputI<INftData[]>> => {
    try {        
        const outputNfts: Promise<INftData>[] = nftsWithoutImages.map(async (nft)=>{
            try {
                const res = await fetchImageByNftId(nft.id);
                if(res.err) throw new Error(res.msg);
                if(res.output === undefined) throw new Error("output is undefined");
                const imgURL = res.output;
                return { ...nft, imgURL}
            } catch (error) {
                return {
                    ...nft,
                    imgURL: ""
                }
            }
        });
        const output = await Promise.all(outputNfts);
        return {err: false, output};
    } catch (error) {
        return {err: true, msg: `injectNftsImages.ts::injectNftsImages():: Failed to inject nfts images! || \n${error}`};
    }
}

export default injectNftsImages;