import { ethers } from "ethers";
import OutputI from "../../../interfaces/Output";

import getSignerAddress from "../utils/getSignerAddress";
import ContractIdentifiers from "../../../constants/contractIdentifiers";
import getContractInstance from "../utils/getContractInstance";

/**
 * @KASH
 * @TODO Get the earn rate.
 * @param provider - A Web3Provider signed by the user's connected wallet.
 * @returns The earn rate.
 */
const fetchEarnRate = async (provider: ethers.providers.Web3Provider): Promise<OutputI<number>> => {
    try {
        let stakingContract = await getContractInstance(provider, ContractIdentifiers.app.EternalVikingsStaking);
        let yielderContract = await getContractInstance(provider, ContractIdentifiers.app.EternalVikingsYielder);
        let currentYield = await yielderContract.getCurrentEarningsRate();
        let wallet = await getSignerAddress(provider)
        let vikings = await stakingContract.walletEVStakeCount(wallet)
        const output = parseFloat(ethers.utils.formatEther(currentYield.mul(vikings))); // Temp
        return {err: false, output};
    } catch (error) {
        return {err: true, msg: `fetch-earn-rate/index.ts::fetchEarnRate():: Failed to fetch earn rate! || \n${error}`};
    }
}

export default fetchEarnRate;