import React from "react";
import { Polygon } from "../../../../assets/images";
import EVGenericButton from "../../../../components/ev-generic-components/EVGenericButton";
import EVGenericText from "../../../../components/ev-generic-components/EVGenericText";
import { useAppSelector } from "../../../../state/hooks";
import { selectWalletInfo } from "../../../../state/wallet-info-state";
import "./SignOutPopup.scss";

export const SignOutPopup: React.FC = () => {
  const address = useAppSelector(selectWalletInfo("address")) as string;

  const onSignOutClick = () => {
    window.location.reload();
  };

  return (
    <>
      <img id={"polygon"} src={Polygon} alt="polygon" />
      <div className="signout-popup m-pad flex-center column round-corners-narrow">
        <EVGenericText
          text={"You are logged in with\nthis wallet:"}
          type={"h3"}
          size={"x-small"}
          extraClassNames={"secondary s-mb"}
        />
        <EVGenericText text={address} extraClassNames={"elipsis m-mb"} />
        <EVGenericButton text={"Sign Out"} cb={onSignOutClick} isDark={true} />
      </div>
    </>
  );
};
