import React from "react";
import "./AccountPanelLayout.scss";
import LayoutCurrencies from "./components/currency-display/LayoutCurrencies";
import LayoutStakingGrids from "./components/staking-grid/LayoutStakingGrids";

const AccountPanelLayout: React.FC = () => {
  return (
    <main className="account-panel-layout-container flex column justify-start align-center xl-mt m-mb">
      <LayoutCurrencies />
      <LayoutStakingGrids />
    </main>
  );
};

export default AccountPanelLayout;
