import { createAsyncThunk } from "@reduxjs/toolkit";
import { ethers } from "ethers";
import { selectWalletInfo } from "..";
import OutputI from "../../../interfaces/Output";
import mintVikingWithGold from "../../../services/ethers/mint-viking-with-gold";
import ReducerSliceNames from "../../constants/reducerSliceNames";
import { useAppSelector } from "../../hooks";

export const mintVikingActionName = 'MintViking';

/**
 * @returns The new a amount of gold after claiming.
 */
const mintVikingAsyncAction = createAsyncThunk(
  `${ReducerSliceNames.WALLET_INFO}/${mintVikingActionName}`,
  async (provider: ethers.providers.Web3Provider): Promise<OutputI<number>> => {
    try {   
      const claimGoldResult = await mintVikingWithGold(provider);
      if(claimGoldResult.err) throw new Error(claimGoldResult.msg);
      if(claimGoldResult.output === undefined) throw new Error("New gold amount is undefined");
      const output = claimGoldResult.output;
      return { err: false, output };
    } catch (error) {
      const msg = `mintVikingAsyncAction.ts::mintVikingAsyncAction():: Failed to claim gold! | \n${error}`;
      return { err: true, msg };
    }
  }
);
export default mintVikingAsyncAction;