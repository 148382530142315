import React from "react";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { closePopup, getPopup, IPopupSlice } from "../../state/popup-state";
import EVGenericButton from "../ev-generic-components/EVGenericButton";
import EVGenericText from "../ev-generic-components/EVGenericText";
import "./Popup.scss";

const Popup: React.FC = () => {
  const { actionFn, actionNameTxt, subtitleTxt, titleTxt } = useAppSelector(
    getPopup()
  ) as IPopupSlice;

  const dispatch = useAppDispatch();

  const onClosePopUp = () => dispatch(closePopup());

  const onActionButtonClick = () => {
    onClosePopUp();
    actionFn();
  };

  return (
    <div className="popup-container flex-center">
      <div className="popup-wrapper flex-center column round-corners-narrow l-pad">
        <EVGenericText text={titleTxt} size={"medium"} type={"h2"} />
        <EVGenericText
          text={subtitleTxt}
          type={"h3"}
          extraClassNames={"m-mt"}
        />
        <div className="ctas flex-center l-mt">
          <EVGenericButton
            text={"Close"}
            isDark={true}
            extraClassNames={"s-mr"}
            cb={onClosePopUp}
          />
          <EVGenericButton text={actionNameTxt} cb={onActionButtonClick} />
        </div>
      </div>
    </div>
  );
};

export default Popup;
