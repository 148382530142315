import React from "react";
import EVGenericText from "../ev-generic-components/EVGenericText";

export type NftCardFooterProps = {
  title: string;
  numOfDaysStaked?: number;
};

export const NftCardFooter: React.FC<NftCardFooterProps> = ({
  title,
  numOfDaysStaked,
}) => (
  <div className="nft-card-footer s-pad flex space-between align-center w-all">
    <EVGenericText text={title} size={"x-small"} type={"p"} />
    {numOfDaysStaked && (
      <EVGenericText
        text={numOfDaysStaked + " days\nstaked"}
        size={"custom"}
        type={"p"}
        customCSS={{ fontSize: "0.875rem" }}
      />
    )}
  </div>
);
