import { ethers } from "ethers";
import OutputI from "../../../interfaces/Output";

import getSignerAddress from "../utils/getSignerAddress";
import ContractIdentifiers from "../../../constants/contractIdentifiers";
import getContractInstance from "../utils/getContractInstance";

/**
 * @KASH
 * @TODO Get the current amount of gold.
 * @param provider - A Web3Provider signed by the user's connected wallet.
 * @returns The current amount of gold.
 */
const fetchCurrentGold = async (provider: ethers.providers.Web3Provider): Promise<OutputI<number>> => {
    try {
        let goldToken = await getContractInstance(provider, ContractIdentifiers.core.EternalVikingsGoldToken);
        let wallet = await getSignerAddress(provider);
        let balance = await goldToken.balanceOf(wallet);
        const output = parseInt(ethers.utils.formatEther(balance.toString()));
        return {err: false, output};
    } catch (error) {
        return {err: true, msg: `fetch-current-gold/index.ts::fetchCurrentGold():: Failed to fetch current gold! || \n${error}`};
    }
}

export default fetchCurrentGold;