import { ethers } from "ethers";
import validateWalletAddress from "../../../utils/validateWalletAddress";

/**
 * @KASH
 * @dev **Nothing to do here**, just wanted you to know you have this util function available 😀.
 * @param provider - A Web3Provider signed by the user's connected wallet.
 * @returns The user's connetcted wallet address.
 */
const getSignerAddress = async (provider: ethers.providers.Web3Provider): Promise<string> => {
    const walletAddr = await provider.getSigner().getAddress();
    if (!walletAddr || validateWalletAddress(walletAddr).err)
        throw new Error(`wallet address is invalid! | address: ${walletAddr}`);
    return walletAddr;
};
export default getSignerAddress;