import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { ethers } from 'ethers';
import IGetWalletInfoResponse from '../../services/ethers/get-wallet-info/interfaces/GetWalletInfoResponse';
import AsyncActionStatus from '../constants/asyncActionStatus';
import ReducerSliceNames from '../constants/reducerSliceNames';
import IDefaultSlice from '../interfaces/DefaultSlice';
import { RootState } from '../store';
import claimGoldAsyncAction from './actions/claimGoldAsyncAction';
import mintVikingAsyncAction from './actions/mintVikingAsyncAction';
import placeAuctionBidAsyncAction from './actions/placeAuctionBidAsyncAction';
import connectWalletAsyncAction from './actions/connectWalletAsyncAction';
import stakeNftsAsyncAction from './actions/stakeNftsAsyncAction';
import unstakeNftsAsyncAction from './actions/unstakeNftsAsyncAction';
import claimAuctionGoldAsyncAction from './actions/claimAuctionGoldAsyncAction';

export interface IWalletInfoSliceState extends IDefaultSlice, IGetWalletInfoResponse {
  provider: ethers.providers.Web3Provider,
  address: string,
  mintVikingSuccess: boolean
}

export const walletInfoInitState: IWalletInfoSliceState = {
  provider: {} as ethers.providers.Web3Provider,
  status: AsyncActionStatus.IDLE,
  currentGold: 0,
  claimableGold: 0,
  stakedNfts: [],
  unstakedNfts: [],
  earnRate: 0,
  address: '',
  highestAuctionBid: 0,
  auctionBid: 0,
  mintVikingSuccess: false
};

const extraReducers = (builder: ActionReducerMapBuilder<IWalletInfoSliceState>)=>{
    builder
    // ===== Connect Wallet ===== START
    .addCase(connectWalletAsyncAction.pending, (state) => {
      state.status = AsyncActionStatus.LOADING;
    })
    .addCase(connectWalletAsyncAction.fulfilled, (state, action) => {
      if(!action.payload.output){
        state = {...walletInfoInitState}
      }
      else{
        const {provider, address, claimableGold, currentGold, stakedNfts, unstakedNfts, earnRate, highestAuctionBid, auctionBid} = action.payload.output
        state.status = AsyncActionStatus.IDLE;
        state.provider = provider;
        state.currentGold = currentGold;
        state.claimableGold = claimableGold;
        state.stakedNfts = stakedNfts;
        state.unstakedNfts = unstakedNfts;
        state.earnRate = earnRate;
        state.address = address;
        state.highestAuctionBid = highestAuctionBid;
        state.auctionBid = auctionBid;
        }
      })
    .addCase(connectWalletAsyncAction.rejected, (state) => {
      state = {...walletInfoInitState};
    })
    // ===== Connect Wallet ===== END
    // ===== Claim Gold ===== Start
    .addCase(claimGoldAsyncAction.pending, (state) => {
      state.status = AsyncActionStatus.LOADING;
    })
    .addCase(claimGoldAsyncAction.fulfilled, (state, action) => {
      if(action.payload.err || action.payload.output === undefined) return;
        state.status = AsyncActionStatus.IDLE;
        state.currentGold = action.payload.output;
        state.claimableGold = 0;
    })
    .addCase(claimGoldAsyncAction.rejected, (state) => {
      state.status = AsyncActionStatus.FAILED;
    })
    // ===== Claim Gold ===== END
    // ===== Mint Viking ===== Start
    .addCase(mintVikingAsyncAction.pending, (state) => {
        state.status = AsyncActionStatus.LOADING;
    })
    .addCase(mintVikingAsyncAction.fulfilled, (state, action) => {
        if(action.payload.err || action.payload.output === undefined) return;
          state.status = AsyncActionStatus.IDLE;
          state.currentGold = action.payload.output;
          state.mintVikingSuccess = true; 
    })
    .addCase(mintVikingAsyncAction.rejected, (state) => {
        state.status = AsyncActionStatus.FAILED;
    })
    // ===== Mint Viking ===== END
    // ===== Place Auction Bid ===== Start
    .addCase(placeAuctionBidAsyncAction.pending, (state) => {
        state.status = AsyncActionStatus.LOADING;
    })
    .addCase(placeAuctionBidAsyncAction.fulfilled, (state, action) => {        
        if(action.payload.err || action.payload.output === undefined) return;
          state.status = AsyncActionStatus.IDLE;
          state.currentGold = action.payload.output.fetchCurrentGoldResult;
          state.highestAuctionBid = action.payload.output.fetchHighestBid;
          state.auctionBid = action.payload.output.fetchHighest;  
    })
    .addCase(placeAuctionBidAsyncAction.rejected, (state) => {
        state.status = AsyncActionStatus.FAILED;
    })
    // ===== Place Auction Bid ===== END
    // ===== Claim Auction Gold ===== Start
    .addCase(claimAuctionGoldAsyncAction.pending, (state) => {
        state.status = AsyncActionStatus.LOADING;
    })
    .addCase(claimAuctionGoldAsyncAction.fulfilled, (state, action) => {        
        if(action.payload.err || action.payload.output === undefined) return;
          state.status = AsyncActionStatus.IDLE;
          state.currentGold = action.payload.output.fetchCurrentGoldResult;
          state.auctionBid = action.payload.output.fetchHighest;  
    })
    .addCase(claimAuctionGoldAsyncAction.rejected, (state) => {
        state.status = AsyncActionStatus.FAILED;
    })
    // ===== Claim Auction Gold ===== END
    // ===== Stake Nfts ===== Start
    .addCase(stakeNftsAsyncAction.pending, (state) => {
      state.status = AsyncActionStatus.LOADING;
    })
    .addCase(stakeNftsAsyncAction.fulfilled, (state, action) => {
      if(action.payload.err || action.payload.output === undefined) return;
      const {earnRate, stakedNfts, unstakedNfts} = action.payload.output;
      state.earnRate = earnRate;
      state.stakedNfts = stakedNfts;
      state.unstakedNfts = unstakedNfts;
      state.status = AsyncActionStatus.IDLE;
    })
    .addCase(stakeNftsAsyncAction.rejected, (state) => {
      state.status = AsyncActionStatus.FAILED;
    })
    // ===== Stake Nfts ===== END
    // ===== Unstake Nfts ===== Start
    .addCase(unstakeNftsAsyncAction.pending, (state) => {
      state.status = AsyncActionStatus.LOADING;
    })
    .addCase(unstakeNftsAsyncAction.fulfilled, (state, action) => {
      if(action.payload.err || action.payload.output === undefined) return;
      const {earnRate, stakedNfts, unstakedNfts} = action.payload.output;
      state.earnRate = earnRate;
      state.stakedNfts = stakedNfts;
      state.unstakedNfts = unstakedNfts;
      state.status = AsyncActionStatus.IDLE;
    })
    .addCase(unstakeNftsAsyncAction.rejected, (state) => {
      state.status = AsyncActionStatus.FAILED;
    })
    // ===== Unstake Nfts ===== END
  }

const walletInfoSlice = createSlice({
  name: ReducerSliceNames.WALLET_INFO,
  initialState: walletInfoInitState,
  reducers: {
    disconnectWallet: (state)=>{
      state = {...walletInfoInitState}
    }
  },
  extraReducers
});

export const { disconnectWallet } = walletInfoSlice.actions;
export type WalletInfoSelectors = 'provider' | 'status' | 'currentGold' | 'claimableGold' | 'stakedNfts' | 'unstakedNfts' | 'earnRate' | 'address';
export const selectWalletInfo = (selector: WalletInfoSelectors)=>(state: RootState) => state.walletInfo[selector];
export const getWalletInfo = ()=>(state: RootState) => state.walletInfo;
export default walletInfoSlice.reducer;
