import { ethers } from "ethers";
import OutputI from "../../../interfaces/Output";

import getSignerAddress from "../utils/getSignerAddress";
import ContractIdentifiers from "../../../constants/contractIdentifiers";
import getContractInstance from "../utils/getContractInstance";

/**
 * @KASH
 * @TODO Get the highest auction bid.
 * @param provider - A Web3Provider signed by the user's connected wallet.
 * @returns The the highest auction bid.
 */
const fetchHighestAuctionBid = async (provider: ethers.providers.Web3Provider): Promise<OutputI<number>> => {
    try {        
        let evSacrificer = await getContractInstance(provider, ContractIdentifiers.app.EternalVikingsSacrificer);
        let highestBid = await evSacrificer.ringHighestBid(2)
        const output = parseInt(ethers.utils.formatEther(highestBid));
        return {err: false, output};
    } catch (error) {
        return {err: true, msg: `fetch-highest-auction-bid/index.ts::fetchHighestAuctionBid():: Failed to fetch claimable gold! || \n${error}`};
    }
}

export default fetchHighestAuctionBid;