import { ethers } from "ethers";
import INftData from "../../../interfaces/NftData";
import OutputI from "../../../interfaces/Output";
import injectNftsImages from '../../../utils/injectNftsImages';

import getSignerAddress from "../utils/getSignerAddress";
import ContractIdentifiers from "../../../constants/contractIdentifiers";
import getContractInstance from "../utils/getContractInstance";

/**
 * @KASH
 * @TODO - Get the ids of the unstaked nfts.
 * @param provider - A Web3Provider signed by the user's connected wallet.
 * @returns An array of the unstaked nfts.
 */
const fetchUnstakedNfts = async (provider: ethers.providers.Web3Provider): Promise<OutputI<INftData[]>> => {
    try {
        let wallet = await getSignerAddress(provider);
        let stakingContract = await getContractInstance(provider, ContractIdentifiers.app.EternalVikingsStaking);
        let unstakedTokens = await stakingContract.getUnstakedVikingIdsOfUser(wallet);
        const nftsWithoutImage: Omit<INftData, 'imgURL'>[] = unstakedTokens.map((t: ethers.BigNumber) => {
            return {
                id: t.toNumber()
            }
        })

        // Fetch nft images
        const injectNftsImagesResult = await injectNftsImages(nftsWithoutImage);
        if (injectNftsImagesResult.err) throw new Error(injectNftsImagesResult.msg);
        if (injectNftsImagesResult.output === undefined) throw new Error("injectNftsImages output is undefined");

        const output = injectNftsImagesResult.output;
        return {err: false, output};
    } catch (error) {
        return {err: true, msg: `fetch-unstaked-nfts/index.ts::fetchUnstakedNfts():: Failed to fetch the unstaked nfts! || \n${error}`};
    }
}

export default fetchUnstakedNfts;