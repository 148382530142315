import React from "react";
import EVGenericButton from "../../../../../../components/ev-generic-components/EVGenericButton";
import EVGenericText from "../../../../../../components/ev-generic-components/EVGenericText";
import {
  txtActionNameAddToMetamask,
  txtActionNameClaimGold,
  txtCurrencyNameClaimableGold,
  txtCurrencyNameGold,
} from "../../../../../../constants/texts";
import "./CurrencyDisplayLeftSection.scss";

export type CurrencyDisplayLeftSectionProps = {
  amount: number;
  image: string;
  currencyType:
    | typeof txtCurrencyNameGold
    | typeof txtCurrencyNameClaimableGold;
  actionFn: () => void;
  actionName: typeof txtActionNameAddToMetamask | typeof txtActionNameClaimGold;
};

const CurrencyDisplayLeftSection: React.FC<CurrencyDisplayLeftSectionProps> = ({
  amount,
  image,
  currencyType,
  actionFn,
  actionName,
}) => (
  <div className="currency-display-left-section-container flex column space-between aling-start">
    <div className="currency-wrapper flex justify-start align-center">
      <div className="image-wrapper">
        <img src={image} alt="claimable-gold" />
      </div>
      <div className="currency-text-wrapper flex column space-between ">
        <EVGenericText
          text={String(amount)}
          size={"huge"}
          weight={"bold"}
          customCSS={{ fontFamily: "Inter" }}
          extraClassNames="currency-amount"
        />
        <EVGenericText
          text={currencyType}
          customCSS={{ fontFamily: "Vikings" }}
          extraClassNames="currency-name"
        />
      </div>
    </div>
    <div className="cta-wrapper m-mt flex column justify-end align-start">
      <EVGenericButton text={actionName} isDark={true} cb={actionFn} />
    </div>
  </div>
);

export default CurrencyDisplayLeftSection;
