const listenToChainChanges = (provider: any) => {
    try {
        provider.on("chainChanged", (chainId: number) => {
            window.location.reload();
        });
    } catch (error) {
        console.error(`attachWalletProviderEventListeners.ts::listenToChainChanges():: Failed to attach on chain change listener | \n${error}`);
    }
};

const listenToAccountChanges = (provider: any) => {
    try {
        
        provider.on("accountsChanged", (accounts: string[]) => {
            window.location.reload();
        });
    } catch (error) {
        console.error(`attachWalletProviderEventListeners.ts::listenToAccountChanges():: Failed to attach on account change listener | \n${error}`);
    }
}

const listenToDisconnect = (provider: any) => {
    try {
        provider.on("disconnect", (error: { code: number; message: string }) => {
            window.location.reload();
        });
    } catch (error) {
        console.error(`attachWalletProviderEventListeners.ts::listenToDisconnect():: Failed to attach on disconnect listener | \n${error}`);
    }
}

export const listenToConnect = (provider: any) => {
    try {
        provider.on("connect", (info: { chainId: string }) => {
            //if(parseInt(info.chainId) !== 1) window.location.reload();
        })
    } catch (error) {
        console.error(`attachWalletProviderEventListeners.ts::listenToDisconnect():: Failed to attach on disconnect listener | \n${error}`);
    }
}

const attachWalletProviderEventListeners = (provider: any) =>{
    try {
        if (!provider) throw new Error("provider is undefined");
        listenToChainChanges(provider);
        listenToAccountChanges(provider);
        listenToDisconnect(provider);
    } catch (error) {
        console.error(`attachWalletProviderEventListeners.ts::attachWalletProviderEventListeners():: Failed to attach listeners | \n${error}`);
    }
}

export default attachWalletProviderEventListeners;