import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import WalletInfoReducer from "./wallet-info-state";
import PopupReducer from "./popup-state";

export const store = configureStore({
  reducer: {
    walletInfo: WalletInfoReducer,
    popup: PopupReducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
