import React from "react";
import { Coins, Sack } from "../../../../../../assets/images";
import {
    getWalletInfo,
    IWalletInfoSliceState,
  } from "../../../../../../state/wallet-info-state";
  import syncMetamask from "../../../../../../services/ethers/sync-metamask";
import CurrencyDisplay from "./currency-display-item/CurrencyDisplay";
import { useAppDispatch, useAppSelector } from "../../../../../../state/hooks";
import {
    textHelperTextGold,
    txtActionNameAddToMetamask,
    txtCurrencyNameGold,
    txtCurrencyNameClaimableGold
} from "../../../../../../constants/texts";
import { ethers } from "ethers";

export type CurrencyDisplayProps = {
    provider: ethers.providers.Web3Provider;
    currentGold: number;
    earnRate: number
}

const CurrencyDisplayComponent: React.FC<CurrencyDisplayProps>= ({
    provider,
    currentGold,
    earnRate
}) => { 
    const onAddToMetamaskClick = () => syncMetamask(provider);

    return (
        <div className="w-all">
            <CurrencyDisplay
                amount={currentGold}
                image={Coins}
                currencyType={txtCurrencyNameClaimableGold}
                actionFn={onAddToMetamaskClick}
                actionName={txtActionNameAddToMetamask}
                helperText={textHelperTextGold}
            />
        </div>
    );
};

export default CurrencyDisplayComponent;
