import "./App.scss";
import AppBackground from "./components/app-background/AppBackground";
import NavBar from "./features/nav-bar/NavBar";
import NavRouter from "./features/nav-bar/components/nav-router/NavRouter";
import ConnectWalletLayout from "./features/connect-wallet-layout/ConnectWalletLayout";
import { useState, useEffect } from "react";
import { useAppSelector } from "./state/hooks";
import { selectWalletInfo } from "./state/wallet-info-state";
import Popup from "./components/popup/Popup";
import { selectIsPopupOpen } from "./state/popup-state";

function App() {
  const address = useAppSelector(selectWalletInfo("address")) as string;

  const isPopupOpen = useAppSelector(selectIsPopupOpen()) as boolean;

  const [connected, setConnected] = useState(false);

  useEffect(() => {
    setConnected(!!address.length);
  }, [address, isPopupOpen]);

  return (
    <>
      <AppBackground />
      {isPopupOpen && <Popup />}
      <div className="App flex column justify-start align-center">
        {connected ? (
          <>
            <NavBar />
            <NavRouter />
          </>
        ) : (
          <ConnectWalletLayout />
        )}
      </div>
    </>
  );
}

export default App;
