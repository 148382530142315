import { createAsyncThunk } from "@reduxjs/toolkit";
import { ethers } from "ethers";
import { selectWalletInfo } from "..";
import OutputI from "../../../interfaces/Output";
import claimGold from "../../../services/ethers/claim-gold";
import ReducerSliceNames from "../../constants/reducerSliceNames";
import { useAppSelector } from "../../hooks";

export const claimGoldActionName = 'ClaimGold';

/**
 * @returns The new a amount of gold after claiming.
 */
const claimGoldAsyncAction = createAsyncThunk(
  `${ReducerSliceNames.WALLET_INFO}/${claimGoldActionName}`,
  async (provider: ethers.providers.Web3Provider): Promise<OutputI<number>> => {
    try {   
      const claimGoldResult = await claimGold(provider);
      if(claimGoldResult.err) throw new Error(claimGoldResult.msg);
      if(claimGoldResult.output !== undefined) throw new Error("New gold amount is undefined");
      const output = claimGoldResult.output;
      return { err: false, output };
    } catch (error) {
      const msg = `claimGoldAsyncAction.ts::claimGoldAsyncAction():: Failed to claim gold! | \n${error}`;
      return { err: true, msg };
    }
  }
);
export default claimGoldAsyncAction;