import React from "react";
import { ConnectBG, ConnectBGLogo } from "../../assets/images";
import EVGenericConnectWalletButton from "../../components/ev-generic-components/EVGenericConnectWalletButton";
import EVGenericText from "../../components/ev-generic-components/EVGenericText";
import EVLogo from "../../components/ev-generic-components/EVLogo";
import AsyncActionStatus from "../../state/constants/asyncActionStatus";
import { useAppSelector } from "../../state/hooks";
import { selectWalletInfo } from "../../state/wallet-info-state";
import { txtTitle, txtSubtitle } from "./constants/texts";
import "./ConnectWalletLayout.scss";

const ConnectWalletLayout: React.FC = () => {
  const status = useAppSelector(
    selectWalletInfo("status")
  ) as AsyncActionStatus;
  const address = useAppSelector(selectWalletInfo("address")) as string;

  return (
    <>
      <div className="bg-container">
        <EVLogo />
        <img id={"bg-img"} src={ConnectBG} alt="connect" />
        <img id={"bg-logo"} src={ConnectBGLogo} alt="connect-logo" />
      </div>
      <main className="connect-wallet-container flex justify-start align-center w-all">
        <div className="flex justify-center align-start column">
          <EVGenericText
            text={txtSubtitle}
            type={"h2"}
            extraClassNames={"m-mb"}
          />
          <EVGenericText
            text={txtTitle}
            size={"huge"}
            type={"h1"}
            customCSS={{ fontFamily: "Vikings" }}
            extraClassNames={"m-mb"}
          />
          <EVGenericConnectWalletButton
            disabled={status !== AsyncActionStatus.IDLE && !!address.length}
          />
        </div>
      </main>
    </>
  );
};

export default ConnectWalletLayout;
