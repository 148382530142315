import React from "react";
import "./MarketplacePanelLayout.scss";

import LayoutMintVikings from "./components/mint-vikings-panel/LayoutMintVikings";
import LayoutAuctionPanel from "./components/auction-panel/LayoutAuctionPanel";

const MarketplacePanelLayout: React.FC = () => {
  return (
    <main className="marketplace-panel-layout-container xl-mt m-mb">
      <LayoutMintVikings />
      <LayoutAuctionPanel />
    </main>
  );
};

export default MarketplacePanelLayout;
