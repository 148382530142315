import { createAsyncThunk } from "@reduxjs/toolkit";
import { ethers } from "ethers";
import { selectWalletInfo } from "..";
import OutputI from "../../../interfaces/Output";
import { INftsStakingData } from "../../../services/ethers/get-wallet-info/interfaces/IStakeNftsAsyncActionResponse";
import stakeNfts from "../../../services/ethers/stake-nfts";
import ReducerSliceNames from "../../constants/reducerSliceNames";
import { useAppSelector } from "../../hooks";

export const stakeNftsActionName = 'StakeNfts';
const stakeNftsAsyncAction = createAsyncThunk(
  `${ReducerSliceNames.WALLET_INFO}/${stakeNftsActionName}`,
  async ({provider, nftIds}: {provider: ethers.providers.Web3Provider, nftIds:number[]}): Promise<OutputI<INftsStakingData>> => {
    try {
      const stakeNftsResult = await stakeNfts(nftIds, provider);
      if(stakeNftsResult.err) throw new Error(stakeNftsResult.msg);
      const output = stakeNftsResult.output;
      return { err: false, output };
    } catch (error) {
      const msg = `stakeNftsAsyncAction.ts::stakeNftsAsyncAction():: Failed to stake nfts! | \n${error}`;
      return { err: true, msg };
    }
  }
);
export default stakeNftsAsyncAction;