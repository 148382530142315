import { ethers } from "ethers";
import OutputI from "../../../interfaces/Output";
import fetchCurrentGold from "../fetch-current-gold";

import ContractIdentifiers from "../../../constants/contractIdentifiers";
import getContractInstance from "../utils/getContractInstance";

/**
 * @TODO - Run mint viking tx
 * @param provider - A Web3Provider signed by the user's connected wallet.
 * @returns The new amount of gold after minting.
 */
const mintVikingWithGold = async (provider: ethers.providers.Web3Provider): Promise<OutputI<number>> => {
    try {
        // Do mint viking stuff here...
        let evSacrificer = await getContractInstance(provider, ContractIdentifiers.app.EternalVikingsSacrificer);
        let tx = await evSacrificer.connect(await provider.getSigner()).sacrificeGold();
        await tx.wait();

        // Fetching updated gold amount in wallet.
        const fetchCurrentGoldResult = await fetchCurrentGold(provider);
        if(fetchCurrentGoldResult.err) throw new Error(fetchCurrentGoldResult.msg);
        if (fetchCurrentGoldResult.output === undefined) throw new Error("Current gold amount is undefined");
        return {err: false, output: fetchCurrentGoldResult.output};
    } catch (error) {
        return {err: true, msg: `claim-gold/index.ts::claimGold():: Failed to claim gold! || \n${error}`};
    }
}

export default mintVikingWithGold;