import React from "react";
import NavItem from "./NavItem";
import navItemsData from "../../constants/navItemsData";
import "./NavBarNavItems.scss";

const NavBarNavItems: React.FC = () => (
  <nav className="nav-items-container flex space-evenly align-center">
    {navItemsData.map((i, index) => (
      <NavItem {...i} key={index} />
    ))}
  </nav>
);
export default NavBarNavItems;
