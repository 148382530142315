import { IPopupContentTexts } from "../../../../../state/popup-state";
import { txtStakePopupTitle, txtStakePopupSubtitle, txtStakePopupButton, txtUnstakePopupTitle, txtUnstakePopupSubtitle, txtUnstakePopupButton } from "../../../../../constants/texts";

export const generateStakePopupTexts = (): IPopupContentTexts => ({
    titleTxt: txtStakePopupTitle,
    subtitleTxt: txtStakePopupSubtitle,
    actionNameTxt: txtStakePopupButton
});

export const generateUnstakePopupTexts = (): IPopupContentTexts => ({
    titleTxt: txtUnstakePopupTitle,
    subtitleTxt: txtUnstakePopupSubtitle,
    actionNameTxt: txtUnstakePopupButton
});
