import React, { useCallback } from "react";
import { OpenSeaLogo } from "../../../../../../assets/images";
import EVGenericButton from "../../../../../../components/ev-generic-components/EVGenericButton";
import EVGenericText from "../../../../../../components/ev-generic-components/EVGenericText";
import { useAppSelector } from "../../../../../../state/hooks";
import { getWalletInfo } from "../../../../../../state/wallet-info-state";
import "./StakingGridContentNoVikings.scss";

const StakingGridContentNoVikings: React.FC = () => {
  const { stakedNfts, unstakedNfts } = useAppSelector(getWalletInfo());

  const canMint = useCallback<() => boolean>(() => {
    const hasUptoOneStakedNFT = unstakedNfts.length <= 1;
    const hasUptoOneNFT = stakedNfts.length + unstakedNfts.length <= 1;
    return hasUptoOneStakedNFT && hasUptoOneNFT;
  }, [stakedNfts, unstakedNfts]);

  return (
    <div className="staking-grid-content-no-vikings flex-center column w-all">
      <EVGenericText
        text={
          "Get An Eternal Viking from Opensea"
        }
        extraClassNames={"m-mb"}
      />
      <EVGenericButton
          text={"OpenSea"}
          ImgURL={OpenSeaLogo}
          imgAlt={"go-to-opensea"}
          isDark={true}
          cb={() => window.open("https://opensea.io/collection/eternalvikings", "_blank")}
        />
    </div>
  );
};

export default StakingGridContentNoVikings;
