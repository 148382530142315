export const txtEarnRate = "Daily Earn Rate: ";
export const txtCurrenciesSectionTitle = "My Wallet";
export const txtCurrencyNameGold = "$GOLD";
export const txtCurrencyNameClaimableGold = "Claimable $GOLD";
export const txtActionNameAddToMetamask = "Add to Metamask";
export const txtActionNameClaimGold = "Claim $GOLD";
export const textHelperTextClaimableGold = `This represents how much on chain "$GOLD" you have available to claim. Think of it as cash in your hand.`;
export const textHelperTextGold = `This represents how much on chain "$GOLD" you have in your wallet.`;
export const txtClaimGoldPopupTitle = `Claim $GOLD`;
export const txtClaimGoldPopupSubtitle = `Remember, claiming tokens will\nrequire a 20% tax (10% burned +\n10% redistributed to other stakers).`;
export const txtClaimGoldPopupButton = `Claim $GOLD`;

export const txtStakePopupTitle = "Staking";
export const txtUnstakePopupTitle = "Unstaking";
export const txtStakePopupSubtitle = "Staking your Viking sends them into\nbattle to earn you $GOLD - are you\nready for war?";
export const txtUnstakePopupSubtitle = "By unstaking your Vikings you'll stop\ngenerating $GOLD";
export const txtStakePopupButton = "Stake";
export const txtUnstakePopupButton = "Unstake";

