import { createAsyncThunk } from "@reduxjs/toolkit";
import { ethers } from "ethers";
import { selectWalletInfo } from "..";
import OutputI from "../../../interfaces/Output";
import { INftsStakingData } from "../../../services/ethers/get-wallet-info/interfaces/IStakeNftsAsyncActionResponse";
import unstakeNfts from "../../../services/ethers/unstake-nfts";
import ReducerSliceNames from "../../constants/reducerSliceNames";
import { useAppSelector } from "../../hooks";

export const unstakeNftsActionName = 'UnstakeNfts';
const unstakeNftsAsyncAction = createAsyncThunk(
  `${ReducerSliceNames.WALLET_INFO}/${unstakeNftsActionName}`,
  async ({provider, nftIds}: {provider: ethers.providers.Web3Provider, nftIds:number[]}): Promise<OutputI<INftsStakingData>> => {
    try {      
      const unstakeNftsResult = await unstakeNfts(nftIds, provider);
      if(unstakeNftsResult.err) throw new Error(unstakeNftsResult.msg);
      const output = unstakeNftsResult.output;
      return { err: false, output };
    } catch (error) {
        console.log(error)
      const msg = `unstakeNftsAsyncAction.ts::unstakeNftsAsyncAction():: Failed to unstake nfts! | \n${error}`;
      return { err: true, msg };
    }
  }
);
export default unstakeNftsAsyncAction;