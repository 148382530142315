import React from "react";
import { Coins, Sack } from "../../../../assets/images";
import EVGenericText from "../../../../components/ev-generic-components/EVGenericText";
import syncMetamask from "../../../../services/ethers/sync-metamask";
import { useAppDispatch, useAppSelector } from "../../../../state/hooks";
import {
  getWalletInfo,
  IWalletInfoSliceState,
} from "../../../../state/wallet-info-state";
import claimGoldAsyncAction from "../../../../state/wallet-info-state/actions/claimGoldAsyncAction";
import CurrencyDisplay from "./components/currency-display-item/CurrencyDisplay";
import {
  textHelperTextClaimableGold,
  textHelperTextGold,
  txtActionNameAddToMetamask,
  txtActionNameClaimGold,
  txtCurrenciesSectionTitle,
  txtCurrencyNameClaimableGold,
  txtCurrencyNameGold,
} from "../../../../constants/texts";
import "./LayoutCurrencies.scss";
import { generateClaimGoldPopupTexts } from "./constants/generateClaimGoldPopupText";
import { openPopup } from "../../../../state/popup-state";

const LayoutCurrencies: React.FC = () => (
  <section className="layout-currencies-container flex-center column w-all">
    <Header />
    <Content />
  </section>
);
export default LayoutCurrencies;

const Header: React.FC = () => (
  <div className="currencies-title w-all flex justify-start align-center">
    <EVGenericText
      text={txtCurrenciesSectionTitle}
      type={"h2"}
      extraClassNames={"s-mb"}
    />
  </div>
);

const Content: React.FC = () => {
  const dispatch = useAppDispatch();
  const { claimableGold, currentGold, earnRate, provider } = useAppSelector(
    getWalletInfo()
  ) as IWalletInfoSliceState;

  const onAddToMetamaskClick = () => syncMetamask(provider);
  const onClaimGoldClick = () => {
    dispatch(
      openPopup({
        ...generateClaimGoldPopupTexts(),
        actionFn: () => dispatch(claimGoldAsyncAction(provider)),
      })
    );
  };

  return (
    <div className="currencies-content s-mt w-all">
      <CurrencyDisplay
        amount={currentGold}
        image={Coins}
        currencyType={txtCurrencyNameGold}
        actionFn={onAddToMetamaskClick}
        actionName={txtActionNameAddToMetamask}
        helperText={textHelperTextGold}
      />
      <CurrencyDisplay
        amount={claimableGold}
        image={Sack}
        currencyType={txtCurrencyNameClaimableGold}
        actionFn={onClaimGoldClick}
        actionName={txtActionNameClaimGold}
        earnRate={earnRate}
        helperText={textHelperTextClaimableGold}
      />
    </div>
  );
};
