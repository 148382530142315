import React from "react";
import { Xicon } from "../../../../../../assets/images";
import EVGenericButton from "../../../../../../components/ev-generic-components/EVGenericButton";
import EVGenericText from "../../../../../../components/ev-generic-components/EVGenericText";
import {
  txtActionNameStakeSelected,
  txtActionNameUnstakeSelected,
  txtTotalNftsSelected,
} from "../../constants/texts";
import "./StakingGridFooter.scss";

export type StakingGridFooterProps = RightProps & LeftProps;

const StakingGridFooter: React.FC<StakingGridFooterProps> = ({
  actionFn,
  actionName,
  totalSelected,
  deselectAll,
}) => (
  <div className="staking-grid-footer flex space-between align-center w-all">
    <Left deselectAll={deselectAll} />
    <Right
      totalSelected={totalSelected}
      actionName={actionName}
      actionFn={actionFn}
    />
  </div>
);

export default StakingGridFooter;

type LeftProps = {
  deselectAll: () => void;
};

const Left: React.FC<LeftProps> = ({ deselectAll }) => (
  <div className="staking-grid-footer-left">
    <img src={Xicon} alt="close" onClick={deselectAll} />
  </div>
);

type RightProps = {
  totalSelected: number;
  actionName:
    | typeof txtActionNameStakeSelected
    | typeof txtActionNameUnstakeSelected;
  actionFn: (nftIds: number[]) => void;
};

const Right: React.FC<RightProps> = ({
  totalSelected,
  actionFn,
  actionName,
}) => (
  <div className="staking-grid-footer-right flex justify-end align-center">
    <EVGenericText
      text={String(totalSelected) + txtTotalNftsSelected}
      size={"x-small"}
      extraClassNames={"m-mr"}
    />
    <EVGenericButton text={actionName} isDark={true} cb={actionFn} />
  </div>
);
