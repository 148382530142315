import Web3Modal, { IProviderOptions, ThemeColors } from "web3modal";
import WalletConnectProvider from '@walletconnect/web3-provider'
import { ethers } from "ethers";
import OutputI from "../../../../interfaces/Output";
import attachWalletProviderEventListeners, { listenToConnect } from "../../utils/attachWalletProviderEventListeners";

const providerOptions: IProviderOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      appName: "EV - Testing",
      infuraId: process.env.REACT_APP_INFURA_PROJECT_ID,
    }
  }
};

const web3modalTheme: ThemeColors ={
    background: "black",
    main: "$font-primary-color",
    secondary: "$font-secondary-color",
    border: "black",
    hover: "grey"
} 

const web3Modal = new Web3Modal({
    network: 'mainnet',
    lightboxOpacity: 0.05,
    providerOptions,
    theme: web3modalTheme
});

listenToConnect(web3Modal);

const openWeb3Modal = async ():Promise<OutputI<ethers.providers.Web3Provider>>=>{
  try {
    const web3ModalInstance = await web3Modal.connect();
    const web3ModalProvider = new ethers.providers.Web3Provider(web3ModalInstance);
    if(!web3ModalProvider) throw new Error("failed to generate a provider!");
    attachWalletProviderEventListeners(web3ModalInstance)
    return {err: false, output: web3ModalProvider}
  } catch (error) {
    return {err: true, msg: `connect-wallet/index.ts::playWithEthers():: Failed while running web3modal! | \n${error}`}
  }
}

export default openWeb3Modal