import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AccountPanelLayout from "../../../account-panel-layout/AccountPanelLayout"; 
import MarketplacePanelLayout from "../../../marketplace-panel-layout/MarketplacePanelLayout";
import ProtectedRoute from "../protected-route";
import { useAppDispatch, useAppSelector } from "../../../../state/hooks";
import {
    getWalletInfo,
    IWalletInfoSliceState,
} from "../../../../state/wallet-info-state";

const NavRouter: React.FC = () => {
    const { 
        stakedNfts,
        unstakedNfts
    } = useAppSelector(
        getWalletInfo()
    ) as IWalletInfoSliceState;

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/account" />} />
      <Route path="/account" element={<AccountPanelLayout />} />
      <Route 
        path={"/marketplace"}
        element={stakedNfts.length + unstakedNfts.length > 0 ?
            // @ts-ignore
            <MarketplacePanelLayout/> :
            <Navigate to='/account' />}      
        />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default NavRouter;
