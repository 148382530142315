import { ethers } from "ethers";
import OutputI from "../../../interfaces/Output";
import { INftsStakingData } from "../get-wallet-info/interfaces/IStakeNftsAsyncActionResponse";
import fetchNftsStakingData from "../utils/fetchNftsStakingData";

import ContractIdentifiers from "../../../constants/contractIdentifiers";
import getContractInstance from "../utils/getContractInstance";

/**
 * @KASH
 * @TODO - Stake nfts.
 * @param provider - A Web3Provider signed by the user's connected wallet.
 * @param nftIds - The ids of the nfts to be staked.
 * @returns All the data that needs to updated after staking action: staked nfts array, unstaked nfts array and the earn rate.
 */
const stakeNfts = async (nftIds: number[], provider: ethers.providers.Web3Provider): Promise<OutputI<INftsStakingData>> => {
    try {
        // staking code goes here...
        let stakingContract = await getContractInstance(provider, ContractIdentifiers.app.EternalVikingsStaking);
        let tx = await stakingContract.connect(await provider.getSigner()).stakeVikings(nftIds);
        await tx.wait()
        
        // fetching updated data.
        const updatedNftsStakingDataResponse = await fetchNftsStakingData(provider);
        if(updatedNftsStakingDataResponse.err) throw new Error(updatedNftsStakingDataResponse.msg);
        if(updatedNftsStakingDataResponse.output === undefined) throw new Error("New data is undefined!");
        const output: INftsStakingData = updatedNftsStakingDataResponse.output;
        return {err: false, output};
    } catch (error) {
        return {err: true, msg: `stake-nfts/index.ts::stakeNfts():: Failed to stake nfts! || \n${error}`};
    }
}

export default stakeNfts;

