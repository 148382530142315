import OutputI from "../interfaces/Output";

const validateWalletAddress = (address: string): OutputI<boolean> => {
    try {
        if(typeof address !== 'string') throw new Error('input is not of type string!');
        const regex = /^(0x)[a-zA-HJ-NP-Z0-9]{40}$/i
        const output = regex.test(address)
        if(!output) throw new Error('string is not a wallet address!');
        return {err: false, output};
    } catch (error) {
        return {err: true, msg: `validateWalletAddress.ts::validateWalletAddress():: Wallet address is invalid! | Input: ${address} | \n${error}`}
    }
}

export default validateWalletAddress;