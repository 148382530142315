import React from "react";
import "./EVLogo.scss";
import { EternalVikingsLogo } from "../../assets/images";
// import ReactGA from "react-ga";

const EVLogo: React.FC = () => {
  const refreshPage = () => {
    // if (process.env.REACT_APP_GA_ID) {
    //   ReactGA.event({
    //     action: "click-on-ev-logo",
    //     category: "button",
    //   });
    // }
    window.location.reload();
  };

  return (
    <img
      src={EternalVikingsLogo}
      alt="eternal-vikings-medium"
      id="eternal-vikings-logo"
      onClick={refreshPage}
    />
  );
};

export default EVLogo;
