import React, { useState } from "react";
import { HelperTextIcon } from "../../../../../../assets/images";
import EarnRate from "./EarnRate";
import "./CurrencyDisplayRightSection.scss";
import EVGenericText from "../../../../../../components/ev-generic-components/EVGenericText";

export type CurrencyDisplayRightSectionProps = HelperTextProps & {
  earnRate?: number;
};

const CurrencyDisplayRightSection: React.FC<
  CurrencyDisplayRightSectionProps
> = ({ earnRate, helperText }) => (
  <div className="currency-display-right-section-container flex column space-between align-end">
    <HelperText helperText={helperText} />
    {earnRate !== undefined && <EarnRate earnRate={earnRate} />}
  </div>
);
export default CurrencyDisplayRightSection;

type HelperTextProps = {
  helperText: string;
};

const HelperText: React.FC<HelperTextProps> = ({ helperText }) => {
  const [showText, setShowText] = useState(false);

  const onMouseEnter = (e: any) => {
    e.preventDefault();
    setShowText(true);
  };
  const onMouseLeave = (e: any) => {
    e.preventDefault();
    setShowText(false);
  };

  return (
    <div className="helper-text-icon-button">
      <img
        src={HelperTextIcon}
        alt="helper-text"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
      {showText && (
        <div className="help-text-paragraph m-pad round-corners-narrow">
          <EVGenericText
            text={helperText}
            size={"x-small"}
            extraClassNames={"secondary"}
          />
        </div>
      )}
    </div>
  );
};
