import React, { useState } from "react";
import INftData from "../../../../interfaces/NftData";
import StakingGridContent from "./components/staking-grid-item-content/StakingGridContent";
import StakingGridFooter from "./components/staking-grid-item-footer/StakingGridFooter";
import StakingGridHeader from "./components/staking-grid-item-header/StakingGridHeader";
import {
  StakingPanelType,
  txtActionNameStakeSelected,
  txtActionNameUnstakeSelected,
} from "./constants/texts";
import "./StakingGrid.scss";

export type StakingGridProps = {
  nfts: INftData[];
  stakingPanelTitle: StakingPanelType;
  actionName:
    | typeof txtActionNameStakeSelected
    | typeof txtActionNameUnstakeSelected;
  actionFn: (nftIds: number[]) => void;
};

export type ISelectedState = {
  selectedNfts: number[];
  setSelectedNfts: React.Dispatch<React.SetStateAction<number[]>>;
};

const StakingGrid: React.FC<StakingGridProps> = ({
  nfts,
  stakingPanelTitle,
  actionFn,
  actionName,
}) => {
  const [selectedNfts, setSelectedNfts] = useState<number[]>([]);

  const selectAll = () => {
    const newState = nfts.map((nft) => nft.id);
    setSelectedNfts([...newState]);
  };

  const deselectAll = () => {
    setSelectedNfts([]);
  };

  const onMainActionButtonClick = () => {
    actionFn([...selectedNfts]);
    deselectAll();
  };

  return (
    <div className="staking-grid-container flex column justify-start align-center w-all round-corners-narrow">
      <StakingGridHeader
        stakingPanelTitle={stakingPanelTitle}
        totalNfts={nfts.length}
        selectAll={selectAll}
      />
      <StakingGridContent
        stakingPanelTitle={stakingPanelTitle}
        nfts={nfts}
        selectedNfts={selectedNfts}
        setSelectedNfts={setSelectedNfts}
      />
      {!!selectedNfts.length && (
        <StakingGridFooter
          totalSelected={selectedNfts.length}
          deselectAll={deselectAll}
          actionName={actionName}
          actionFn={onMainActionButtonClick}
        />
      )}
    </div>
  );
};

export default StakingGrid;
