import { ethers } from "ethers";
import OutputI from "../../../interfaces/Output";

import getSignerAddress from "../utils/getSignerAddress";
import ContractIdentifiers from "../../../constants/contractIdentifiers";
import getContractInstance from "../utils/getContractInstance";

/**
 * @KASH
 * @TODO Get current amount of claimable gold.
 * @param provider - A Web3Provider signed by the user's connected wallet.
 * @returns The current amount of claimable gold.
 */
const fetchClaimableGold = async (provider: ethers.providers.Web3Provider): Promise<OutputI<number>> => {
    try {
        let evYielder = await getContractInstance(provider, ContractIdentifiers.app.EternalVikingsYielder);
        let wallet = await getSignerAddress(provider)
        let claimable = await evYielder.getUserPendingEarnings(wallet)
        const output = parseInt(ethers.utils.formatEther(claimable));
        return {err: false, output};
    } catch (error) {
        return {err: true, msg: `fetch-claimable-gold/index.ts::fetchClaimableGold():: Failed to fetch claimable gold! || \n${error}`};
    }
}

export default fetchClaimableGold;