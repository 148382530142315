import React from "react";
import CurrencyDisplayLeftSection, {
  CurrencyDisplayLeftSectionProps,
} from "../currency-display-left/CurrencyDisplayLeftSection";
import CurrencyDisplayRightSection, {
  CurrencyDisplayRightSectionProps,
} from "../currency-display-right/CurrencyDisplayRightSection";
import "./CurrencyDisplay.scss";

export type CurrencyDisplayProps = CurrencyDisplayLeftSectionProps &
  CurrencyDisplayRightSectionProps;

const CurrencyDisplay: React.FC<CurrencyDisplayProps> = ({
  earnRate,
  amount,
  image,
  currencyType,
  actionFn,
  actionName,
  helperText,
}) => {
  const classname = `currency-display-container round-corners-narrow s-pad w-all ${
    currencyType === "$GOLD" ? "gold" : "claimable-gold"
  }`;

  return (
    <div className={classname}>
      <CurrencyDisplayLeftSection
        amount={amount}
        image={image}
        currencyType={currencyType}
        actionFn={actionFn}
        actionName={actionName}
      />
      <CurrencyDisplayRightSection
        earnRate={earnRate}
        helperText={helperText}
      />
    </div>
  );
};

export default CurrencyDisplay;
