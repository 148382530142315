import React from "react";
import EVLogo from "../../components/ev-generic-components/EVLogo";
import NavBarNavItems from "./components/nav-items/NavBarNavItems";
import NavBarAccountDetails from "./components/user-details/NavBarAccountDetails";
import "./NavBar.scss";

const NavBar: React.FC = () => {
  return (
    <header className="navbar-container w-all">
      <div className="ev-logo-container flex justify-start align-center">
        <EVLogo />
      </div>
      <NavBarNavItems />
      <NavBarAccountDetails />
    </header>
  );
};

export default NavBar;
