const ContractIdentifiers = {
    core: {
        EternalVikings: "EternalVikings",
        EternalVikingsGoldToken: "EternalVikingsGoldToken",
        EternalVikingsWhitelistToken: "EternalVikingsWhitelistToken"
    },
    app: {
        EternalVikingsSacrificer: "EternalVikingsSacrificer",
        EternalVikingsStaking: "EternalVikingsStaking",
        EternalVikingsYielder: "EternalVikingsYielder",
        EternalVikingsMarketplace: "EternalVikingsMarketplace"
    },
    mock: {
        MockSacrificeNFT: "MockSacrificeNFT"
    }
}

export default ContractIdentifiers;