import React from "react";
import { parseImgURL } from "./utils/parseImgURL";

export type NftCardHeaderProps = {
  imgURL: string;
};

export const NftCardHeader: React.FC<NftCardHeaderProps> = ({ imgURL }) => (
  <div className="nft-card-header">
    <img
      className="nft-card-header-img w-all"
      loading="lazy"
      src={parseImgURL(imgURL)}
      alt="nft"
    />
  </div>
);
