import { ethers } from "ethers";
import OutputI from "../../../interfaces/Output";
import fetchClaimableGold from "../fetch-claimable-gold";
import fetchCurrentGold from "../fetch-current-gold";
import fetchHighestAuctionBid from "../fetch-highest-auction-bid";
import fetchUserAuctionBid from "../fetch-user-auction-bid";
import IGetWalletInfoResponse from "./interfaces/GetWalletInfoResponse";
import fetchNftsStakingData from "../utils/fetchNftsStakingData";

/**
 * @KASH
 * @dev **Nothing to do here**.. This is where we get the initial wallet's data from the smart contract.
 * @param provider 
 * @returns The updated state of the wallet info.
 */
const getWalletInfoRequest = async (provider: ethers.providers.Web3Provider): Promise<OutputI<IGetWalletInfoResponse>> => {
    try {
        console.log("a")
        const [currentGold, currentClaimableGold, nftsStakingData, highestAuctionBid, auctionBid] = await Promise.all([
            fetchCurrentGold(provider),
            fetchClaimableGold(provider),
            fetchNftsStakingData(provider),
            fetchHighestAuctionBid(provider),
            fetchUserAuctionBid(provider)
        ])
        console.log("b")
        if(currentGold.err) throw new Error(currentGold.msg);
        console.log("c")
        if(currentGold.output === undefined) throw new Error("Currenct Gold is undefined");
        console.log("d")
        if(currentClaimableGold.err) throw new Error(currentClaimableGold.msg);
        console.log("e")
        if(currentClaimableGold.output === undefined) throw new Error("Currenct Claimable Gold is undefined");
        console.log("f")
        if(nftsStakingData.err) throw new Error(nftsStakingData.msg);
        console.log("g")
        if(nftsStakingData.output === undefined) throw new Error("nftsStakingData output is undefined");
        console.log("h")
        if(highestAuctionBid.err) throw new Error(highestAuctionBid.msg);
        console.log("i")
        if(highestAuctionBid.output === undefined) throw new Error("highestAuctionBid output is undefined");
        console.log("j")
        if(auctionBid.err) throw new Error(auctionBid.msg);
        console.log("k")
        if(auctionBid.output === undefined) throw new Error("auctionBid output is undefined");
        console.log("l")
        const output: IGetWalletInfoResponse = {
        currentGold: currentGold.output,
        claimableGold: currentClaimableGold.output,
        ...nftsStakingData.output,
        highestAuctionBid: highestAuctionBid.output,
        auctionBid: auctionBid.output
        }
        console.log("connect wallet")
        console.log(output)
        return {err: false, output}
    } catch (error) {
        return {err: true, msg: `connect-wallet/api/index.ts::getWalletInfoRequest():: Failed to get wallet info from smart contract | \n${error}`}   
    }
}

export default getWalletInfoRequest;