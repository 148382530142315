import React from "react";
import EVGenericText from "../../../../components/ev-generic-components/EVGenericText";
import StakingGrid from "./StakingGrid";
import "./LayoutStakingGrids.scss";
import {
  txtStakingPanelsSectionTitle,
  StakingPanelType,
  txtActionNameStakeSelected,
  txtActionNameUnstakeSelected,
} from "./constants/texts";
import { useAppDispatch, useAppSelector } from "../../../../state/hooks";
import {
  getWalletInfo,
  IWalletInfoSliceState,
} from "../../../../state/wallet-info-state";
import stakeNftsAsyncAction from "../../../../state/wallet-info-state/actions/stakeNftsAsyncAction";
import unstakeNftsAsyncAction from "../../../../state/wallet-info-state/actions/unstakeNftsAsyncAction";
import { openPopup } from "../../../../state/popup-state";
import {
  generateStakePopupTexts,
  generateUnstakePopupTexts,
} from "./constants/generateStakingPopupTexts";

import { ethers } from "ethers"
import { selectWalletInfo } from "../../../../state/wallet-info-state";

const LayoutStakingGrids: React.FC = () => (
  <section className="layout-staking-grids-container flex column justify-start align-center xl-mt w-all">
    <Header />
    <Content />
  </section>
);
export default LayoutStakingGrids;

const Header: React.FC = () => (
  <div className="staking-grids-title flex justify-start align-center s-mb w-all">
    <EVGenericText text={txtStakingPanelsSectionTitle} type={"h2"} />
  </div>
);

const Content: React.FC = () => {
  const dispatch = useAppDispatch();

  const provider = useAppSelector(selectWalletInfo('provider')) as ethers.providers.Web3Provider;

  const { stakedNfts, unstakedNfts } = useAppSelector(
    getWalletInfo()
  ) as IWalletInfoSliceState;

  const onStakeNftsClick = (nftIds: number[]) => {
    dispatch(
      openPopup({
        ...generateStakePopupTexts(),
        actionFn: () => dispatch(stakeNftsAsyncAction({provider, nftIds})),
      })
    );
  };

  const onUnstakeNftsClick = (nftIds: number[]) => {
    dispatch(
      openPopup({
        ...generateUnstakePopupTexts(),
        actionFn: () => dispatch(unstakeNftsAsyncAction({provider, nftIds})),
      })
    );
  };

  return (
    <div className="staking-grids-content w-all">
      <StakingGrid
        nfts={unstakedNfts}
        stakingPanelTitle={StakingPanelType.UNSTAKED}
        actionName={txtActionNameStakeSelected}
        actionFn={onStakeNftsClick}
      />
      <StakingGrid
        nfts={stakedNfts}
        stakingPanelTitle={StakingPanelType.STAKED}
        actionName={txtActionNameUnstakeSelected}
        actionFn={onUnstakeNftsClick}
      />
    </div>
  );
};
