import React from "react";
import EVGenericText from "../../../../../../components/ev-generic-components/EVGenericText";
import EVGenericButton from "../../../../../../components/ev-generic-components/EVGenericButton";
import { GoldIcon } from "../../../../../../assets/images";
import { UnrevealedEV } from "../../../../../../assets/images";

import "./MintVikingComponent.scss";

export type LayoutMintVikingsProps = {
    actionFn: Function;
    currentGold: number;
    mintVikingSuccess: boolean;
}


const MintVikingComponent: React.FC<LayoutMintVikingsProps> = ({
    actionFn,
    currentGold,
    mintVikingSuccess
}) => { 
  return (
    <div className="mint-vikings-component-container flex column justify-start align-center xl-mt w-all">
         <EVGenericText text={"Mint a Viking With $Gold"} type={"h1"} size={"large"} extraClassNames="l-mt l-mb" />
         <img src={UnrevealedEV} alt="Unrevealed Viking"/>
         <div className="flex-center m-mb m-mt">
            <EVGenericText
                text={"1000"}
                size={"custom"}
                customCSS={{ fontFamily: "Inter", fontSize: "2rem" }}
                />
            <img src={GoldIcon} alt="eth" className="xs-mr gold-icon-img" />
        </div> 
        <EVGenericText text={"Eternal Vikings mint is closed."} type={"h2"} extraClassNames={"m-mt m-mb"} />  
         
         {/*
            currentGold >= parseInt(process.env.REACT_APP_VIKING_GOLD_PRICE || "0") ?
                <EVGenericButton 
                    text={"Mint & Stake 1 Viking"}           
                    disabled={false}
                    isDark={false}
                    cb={() => actionFn()}
                    extraClassNames={"m-mb"}
                />
            :
                !mintVikingSuccess ?
                    <EVGenericText text={"You don't have enough $GOLD"} type={"h2"} extraClassNames={"m-mt m-mb"} />          
                :
                    null
  */}

         {/*
            mintVikingSuccess ?
                <EVGenericText text={"Minted successfully. \nPlease refresh the screen."} type={"h2"} extraClassNames={"m-mb text-center"} />  
            :
                null
*/}
    </div>
  );
};

export default MintVikingComponent;
