import React from "react";
import INftData from "../../interfaces/NftData";
import { NftCardFooter } from "./NftCardFooter";
import { NftCardHeader } from "./NftCardHeader";
import { ISelectedState } from "../../features/account-panel-layout/components/staking-grid/StakingGrid";
import "./NftCard.scss";

export interface NftCardProps extends INftData, ISelectedState {
  onClick?: () => void;
  extraClassNames?: string;
}

const NftCard: React.FC<NftCardProps> = ({
  imgURL,
  id,
  numOfDaysStaked,
  onClick,
  extraClassNames,
  selectedNfts,
  setSelectedNfts,
}) => {
  const onNftCardClick = () => {
    if (selectedNfts.includes(id)) {
      try {
        const newState = selectedNfts.filter((selectedId) => selectedId !== id);
        setSelectedNfts(newState ? [...newState] : []);
      } catch (error) {
        setSelectedNfts([]);
      }
    } else {
      setSelectedNfts([...selectedNfts, id]);
    }
  };

  const className = `nft-card-container ${
    selectedNfts.includes(id) ? "highlighted" : ""
  } round-corners-narrow flex column justify-start align-center ${extraClassNames}`;

  return (
    <div className={className} onClick={onNftCardClick}>
      <NftCardHeader imgURL={imgURL} />
      <NftCardFooter title={"#" + id} numOfDaysStaked={numOfDaysStaked} />
    </div>
  );
};

export default NftCard;
