import React from "react";
import EVGenericText from "../../../../../../components/ev-generic-components/EVGenericText";
import { txtEarnRate } from "../../../../../../constants/texts";

export type EarnRateProps = {
  earnRate: number;
};

const EarnRate: React.FC<EarnRateProps> = ({ earnRate }) => (
  <div className="helper-text-earn-rate">
    <EVGenericText
      text={txtEarnRate + String(earnRate)}
      customCSS={{ fontFamily: "Vikings" }}
    />
  </div>
);

export default EarnRate;
