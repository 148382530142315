import { ethers } from "ethers";
import OutputI from "../../../interfaces/Output";

import getSignerAddress from "../utils/getSignerAddress";
import ContractIdentifiers from "../../../constants/contractIdentifiers";
import getContractInstance from "../utils/getContractInstance";

/**
 * @KASH
 * @TODO Get the current bid of the user
 * @param provider - A Web3Provider signed by the user's connected wallet.
 * @returns The current bid of the user.
 */
const fetchUserAuctionBid = async (provider: ethers.providers.Web3Provider): Promise<OutputI<number>> => {
    try {
        let evSacrificer = await getContractInstance(provider, ContractIdentifiers.app.EternalVikingsSacrificer);
        let wallet = await getSignerAddress(provider);
        let bid = await evSacrificer.ringBids(2,wallet);
        const output = parseInt(ethers.utils.formatEther(bid.toString()));
        return {err: false, output};
    } catch (error) {
        return {err: true, msg: `fetch-user-auction-bid/index.ts::fetchUserAuctionBid():: Failed to fetch current auction bids! || \n${error}`};
    }
}

export default fetchUserAuctionBid;