import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import ReducerSliceNames from "../constants/reducerSliceNames";
import { RootState } from "../store";

export interface IPopupContentTexts {
  titleTxt: string;
  subtitleTxt: string;
  actionNameTxt: string;
}

export interface IPopupSlice extends IPopupContentTexts {
  actionFn: (...args:any)=>void;
  isOpen: boolean
};

const popupSliceInitState: IPopupSlice= {
    isOpen: false,
    titleTxt: "",
    subtitleTxt: "",
    actionNameTxt: "",
    actionFn: ()=>{}
}

const popupSlice = createSlice({
  name: ReducerSliceNames.POPUP,
  initialState: popupSliceInitState,
  reducers: {
    openPopup: (state, action: PayloadAction<Omit<IPopupSlice, 'isOpen'>>)=>{
      state.isOpen = true;
      state.actionFn = action.payload.actionFn
      state.actionNameTxt = action.payload.actionNameTxt
      state.subtitleTxt = action.payload.subtitleTxt
      state.titleTxt = action.payload.titleTxt
    },
    closePopup: (state)=>{
    state.isOpen = false
    state.titleTxt = ""
    state.subtitleTxt = ""
    state.actionNameTxt = ""
    state.actionFn = ()=>{}
    }
  }
});

export const { closePopup, openPopup } = popupSlice.actions;
export const getPopup = ()=>(state: RootState) => state.popup;
export const selectIsPopupOpen = ()=>(state: RootState) => state.popup.isOpen;
export default popupSlice.reducer;