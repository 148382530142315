import React from "react";
import NftCard from "../../../../../../components/nft-card/NftCard";
import INftData from "../../../../../../interfaces/NftData";
import { StakingPanelType } from "../../constants/texts";
import { ISelectedState } from "../../StakingGrid";
import StakingGridContentNoVikings from "../staking-grid-content-no-vikings/StakingGridContentNoVikings";
import "./StakingGridContent.scss";

export type StakingGridContentProps = ISelectedState & {
  nfts: INftData[];
  stakingPanelTitle: StakingPanelType;
};

const StakingGridContent: React.FC<StakingGridContentProps> = ({
  stakingPanelTitle,
  nfts,
  selectedNfts,
  setSelectedNfts,
}) => {
  return (
    <>
      {nfts.length ? (
        <NftsContent
          selectedNfts={selectedNfts}
          setSelectedNfts={setSelectedNfts}
          nfts={nfts}
        />
      ) : (
        <>
          {stakingPanelTitle === StakingPanelType.UNSTAKED && (
            <StakingGridContentNoVikings />
          )}
        </>
      )}
    </>
  );
};

export default StakingGridContent;

const NftsContent: React.FC<
  Omit<StakingGridContentProps, "stakingPanelTitle">
> = ({ nfts, selectedNfts, setSelectedNfts }) => (
  <div className="staking-grid-content-container w-all">
    {nfts.map((nft, index) => (
      <NftCard
        key={index}
        {...nft}
        setSelectedNfts={setSelectedNfts}
        selectedNfts={selectedNfts}
      />
    ))}
  </div>
);
